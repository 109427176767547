<template>
  <div class="hero">
    <div class="hero__image">
      <div class="hero__banner text--white" v-if="banner && bannerLink">
        <b>{{ banner.smallBannerText }}</b>
        <nuxt-link :to="`/evenementen/${bannerLink}`" class="text--white">{{ banner.buttonText }} ></nuxt-link>
      </div>
      <NuxtImg :src="image" :provider="imageProvider"/>
    </div>
    <div class="hero__content" :class="extraContentClasses">
      <div 
        class="block block--angled block--angled-r block--fallout" 
        :class="small ? 'block--p-sm' : 'block--pl-none'"
        ref="leftBlock"
      >
        <slot name="left"></slot>
      </div>
      <div v-if="!small" class="block block--pl-sm block--secondary block--angled block--angled-l text--center" id="intro-cta">
        <slot name="right"></slot>
      </div>
    </div>
    <div class="hero__blending grid--two" :class="{ vcenter: verticalCenter }">
      <div id="block-blending" class="block--primary block--angled block--angled-r block--fallout" ref="blendBlock"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type Banner from '~/typings/strapi/components/banner';

const props = defineProps({
  image: {
    type: String as PropType<string>,
    default: '/images/factory_4.jpg'
  },
  extraContentClasses: {
    type: String as PropType<string>,
    default: ''
  },
  verticalCenter: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  banner: {
    type: Object as PropType<Banner|null>
  },
  bannerLink: {
    type: String as PropType<string>,
  },
  small: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  imageProvider: {
    type: String as PropType<string>,
  }
});

// Calculate the height of the left side of the hero
const leftBlock = ref<HTMLElement | undefined>();
const blendBlock = ref<HTMLElement | undefined>();

const { height, width } = useElementSize(leftBlock, undefined, { box: 'border-box' });

// TODO: maybe determine debounce time based upon pc performance?

function setSizing([newWidth, newHeight]: [number, number]) {
  if (blendBlock.value) {
    blendBlock.value.style.height = `${newHeight}px`;
    blendBlock.value.style.width = `${newWidth}px`;
  }
}

// For initial set
watchOnce([width, height], setSizing);

watchDebounced(
  [width, height],
  setSizing,
  { debounce: 50, maxWait: 100 }
)
</script>
